.pl-20 {
  padding-left: 20px;
}
.pr-20 {
  padding-right: 20px;
}

@mixin inset($d: 0, $position: absolute) {
  position: $position;
  top: $d;
  left: $d;
  right: $d;
  bottom: $d;
}

@mixin invisible-scroll($direction: x) {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    height: 0;
    background: transparent;
  }

  @if $direction == x {
    overflow-x: scroll;
    overflow-y: hidden;
  } @else if $direction == y {
    overflow-x: hidden;
    overflow-y: scroll;
  } @else {
    overflow-x: scroll;
    overflow-y: scroll;
  }
}

@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin debug($offset: 0, $color: red) {
  outline: 1px solid $color;
  outline-offset: $offset;
}

@mixin easing($duration: 600ms, $property: all, $will-change: false) {
  transition: $property $duration cubic-bezier(0.215, 0.61, 0.355, 1);
  @if $will-change {
    will-change: $property;
  }
}

@mixin reset-button {
  width: auto;
  margin: 0;
  padding: 0;
  overflow: visible;
  color: inherit;
  font: inherit;
  line-height: inherit;
  background: transparent;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  resize: none;

  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
  &:focus {
    outline: none;
  }
}

@mixin no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

@mixin loader($size: 1em, $thickness: 1px, $foreground: var(--black), $background: transparent, $speed: 1s) {
  @keyframes loader-spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  position: absolute;
  top: 50%;
  left: 50%;

  width: $size;
  height: $size;

  border: $thickness solid $background;
  border-top: $thickness solid $foreground;
  border-left: $thickness solid $foreground;
  border-radius: 50%;

  animation: loader-spin $speed linear infinite;

  content: "";
  pointer-events: none;
}
