/* ===========================
Default styles
   =========================== */
.main-content {
    padding-top: 106px;
}
.darkmode {
    h1,
    h2,
    h3,
    h4,
    h5,
    a,
    p {
        color: #fff;
        background: #000;
    }
}
