/* ===========================
Our site menu
   =========================== */
.overlay {
    position: fixed;
    left: 0;
    top: 0;
    background: #fff;
    width: 100%;
    padding-top: 106px;
    overflow-y: scroll;
    height: auto;
    z-index: 99;
    transform: translateY(-100%);
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    &.open {
        transform: translateY(0);
        @media (max-width: $tablet) {
            overflow: scroll;
            height: 100%;
        }
    }
    .profile {
        font-size: 3.125vw; // 54px
        line-height: 2.6vw; // 44px
    }
    .socials {
        display: flex;
        flex-direction: column;
        .menu-item {
            width: 100%;

            a {
                color: #fff;
                background: #000;
                justify-content: flex-start;
                @media (max-width: $tablet) {
                    justify-content: flex-end;
                }
            }
            &:nth-of-type(2) {
                a {
                    background: #fff;
                    color: #000;
                }
            }
        }
    }
    .menu-item {
        font-size: $largeFont; // 106px
        text-align: right;
        background: $red;
        &.diary {
            a {
                background: #fff;
                color: #000;
            }
        }
        &.blank {
            background: $yellow;
            @media (max-width: $tablet) {
                display: none !important;
            }
            a {
                pointer-events: none;
            }
        }
        &:nth-of-type(2n) {
            background: $yellow;
        }
        &:nth-of-type(3n) {
            background: $blue;
        }
        a {
            color: #000;
            width: 100%;
            text-transform: uppercase;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 20px;
            // height: 106px;
            @media (max-width: $tablet) {
                height: auto;
                padding: 10px 20px;
            }
        }
    }
    .profile {
        padding: 20px;
        @media (max-width: $tablet) {
            font-size: 24px;
            line-height: 1.2;
        }
    }
}
