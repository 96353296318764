/*============================
The juicy stuff
============================*/
#ball {
    width: 10px;
    height: 10px;
    //background-color: #fff;
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 999;
    transition: width 0.5s ease, height 0.5s ease;
}

#cursor-text {
    position: absolute;
    transform: translate(-50%, -80%);
    pointer-events: none;
    opacity: 0;
    z-index: 999;
    color: $red;
    display: grid;
    place-items: center;
    transition: opacity 150ms ease-in-out;
    font-size: 16px;
}
