/* ===========================
Our theme header
   =========================== */
header {
   position: fixed;
   top: 0;
   width: 100%;
   background: #fff;
   left: 0;
   height: 106px;
   z-index: 999;

   transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
   &.nav-up {
      transform: translateY(-100%);
   }
   .header-container,
   .columns,
   .column {
      height: 100%;
   }
   &.overlay-open {
      @media (min-width: $tablet) {
         .logo-init {
            display: none;
         }
         .logo-alt {
            display: flex !important;
            color: #fff;
         }
         .logo-wrap {
            background: #000;
         }
      }
      .top {
         background: $red;
      }
      .middle {
         background: $yellow;
      }
      .bottom {
         background: $blue;
      }
   }
   .close {
      font-size: $largeFont; // 130px
      color: #000;
      line-height: 0.7;
      position: relative;
      display: block;
      margin-right: 20px;
      @media (max-width: $tablet) {
         font-size: 130px;
      }
      &-col {
         display: flex;
         justify-content: flex-end;
         padding-right: 20px;
         align-items: center;
      }
   }
   .logo {
      font-size: $largeFont; // 130px
      color: #000;
      line-height: 0.7;
      position: relative;
      left: -9px;
      top: -2px;
      display: flex;
      align-items: center;
      height: 100%;
      text-transform: uppercase;
      padding-left: 20px;
      @media (max-width: $tablet) {
         font-size: 130px;
      }

      &-alt {
         display: none;
         font-size: $largeFont;
         align-items: center;
         justify-content: flex-start;
      }
   }
   .button-container {
      display: flex;
      height: 100%;
      cursor: pointer;
      justify-content: center;
      align-items: flex-end;
      flex-direction: column;
      padding-right: 20px;
      span {
         height: 13px;
         background: #000;
         width: 71px;
         margin-bottom: 1.0416666666666667vw;
         display: block;
         @media (max-width: $tablet) {
            margin-bottom: 22px;
         }
         &:last-of-type {
            margin-bottom: 0;
         }
      }
   }
}
