/* ===========================
Layouts
   =========================== */
.columns {
    //display: flex;
    h1 {
        font-size: 10vw;
    }
}
.medium-zoom-overlay {
    background: rgba(255, 255, 255, 0.95) !important;
}
.medium-zoom-image--opened,
.medium-zoom--opened .medium-zoom-overlay {
    z-index: 999;
}
.column {
    //border: 1px solid red;
    img {
        display: block;
        width: 100%;
        transition: all 0.4s linear;
    }

    figure {
        overflow: hidden;
        &:hover img {
            transform: scale(1.005);
        }
    }
}
.block-type-text {
    &.invert {
        background: #000;
        color: #fff;
        height: 100%;
        h1,
        h2,
        h3,
        h4,
        h5,
        p {
            b,
            strong {
                color: #fff;
                background: #000;
            }
        }
    }
}
.block-type-image {
    &.crop {
        height: 100%;
        figure,
        img {
            height: 100%;
            max-width: 100%;
            display: block;
            object-fit: cover;
        }
    }
}
